import {
  type ErrorResponse,
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from "@remix-run/react";
import { getErrorMessage } from "~/utils/misc";
import { HansaLogo } from "./ui/hansa-logo";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <div className="my-[16px] px-[24px] font-normal text-gray-600">
      <p>
        Oh no! Something went wrong on our end. We're already working on fixing
        the issue!
      </p>
      <pre className="text-error">
        {error.status} {error.data}
      </pre>
    </div>
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => (
    <div className="my-[16px] px-[24px] font-normal text-gray-600">
      <p>
        Oh no! Something went wrong on our end. We're already working on fixing
        the issue!
      </p>
      <pre className="text-error">{getErrorMessage(error)}</pre>
    </div>
  ),
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  const params = useParams();

  captureRemixErrorBoundaryError(error);

  if (typeof document !== "undefined") {
    console.error(error);
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-[8px] text-center sm:gap-0">
      <HansaLogo className="my-10" />
      {isRouteErrorResponse(error)
        ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        : unexpectedErrorHandler(error)}
    </div>
  );
}
